@tailwind base;
@tailwind components;
@tailwind utilities;

input:checked + div {
  @apply border-blue-500;
}
input:checked + div svg {
  @apply block;
}

.main_input::-webkit-outer-spin-button,
.main_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main_input[type="number"] {
  -moz-appearance: textfield;
}

.fade-anim {
  animation: fadeAnim 0.2s ease-in-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.basic_transition {
  transition: all 200ms ease-in-out;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
